<template>
	<div :class="themeClass" class="width-fill maink margin-lr-auto">
		<div v-if="goodsList.length == 0" class="not-found-box border-F2F2F2 maink width-fill flex-row-align-center">
			<div class="not-found-innerbox flex-row-align-center font-size14 font-color-666">
				<span class="iconfont icon-tishi3 font-size14 font-color-FF0000"></span>
				<span class="margin-l-10">未在</span>
				<span class="font-color-FF0000">{{ search }}</span>
				<span class="">找到符合条件的相关商品</span>
			</div>
		</div>
		<div>
			<div class="tab bg-color-theme font-color-fff font-size13 text-center margin-t-10">商品</div>
			<div class="gcm-box-bottom-card-three padding-10 bg-fff" v-loading="loading">
				<div class="ml15 mt10">
					<div class="flex-row-space-between ">
						<div class="flex-row">
							<div
								class="goodsTab-box text-center font-size13 pointer-color-theme"
								:class="item.checked ? 'textfon' : 'border-r-F2F2F2'"
								v-for="(item, index) of goodsTabData"
								:key="index"
								@click="goodsTabClick(item)"
							>
								{{ item.name }}
								<span
									v-if="item.id != 4"
									class="font-color-999 font-size16 font-weight700"
									:class="{ 'el-icon-top': item.fSortMode == 1, 'el-icon-bottom': item.fSortMode == 2, 'color-theme': item.checked }"
								></span>
								
							</div>
							<div v-if="requestParams.fIsExchange == 0"  @click="ExcClick(0)" class="goodsTab-box text-center font-size13 pointer-color-theme border-r-F2F2F2">
								看可兑换商品 
							</div>
							<div v-if="requestParams.fIsExchange == 1"   @click="ExcClick(1)"class="goodsTab-box text-center font-size13 pointer-color-theme" :class="requestParams.fIsExchange == 1 ? 'textfon' : 'border-r-F2F2F2'">
								看可兑换商品 
								<span  class="font-color-999 font-size16 font-weight700 iconfont icon-xuanzhibeifen" :class="requestParams.fIsExchange == 1 ? 'color-theme' : ''">	
								</span>
							</div>
						</div>
						<!-- <div class="flex-row margin-tb-auto">
							<div>{{ total }}件商品</div>
							<div class="el-icon-arrow-left margin-tb-auto margin-rl-10 pointer-color-theme" @click="pageChange('-')"></div>
							<div>
								<span class="color-theme margin-r-5">{{ currentPage }}</span>
								<span>/</span>
								<span>{{ Math.ceil(total / pagesize) }}</span>
							</div>
							<div class="el-icon-arrow-right margin-tb-auto margin-rl-10 pointer-color-theme" @click="pageChange('+')"></div>
						</div> -->
					</div>
				</div>
				<div v-if="goodsList.length == 0" class="flex-row-center-center"><svg-icon icon-class="NoDataAvailable" /></div>
				<div class="gcm-box-bottom-card-one-list flex-row-wrap">
					<div
						class="gcm-box-bottom-card-one-item flex-column-center-center margin-t-10"
						@click="getdetail(item.fGoodsID)"
						v-for="(item, index) in goodsList"
						:key="index"
						:class="(index + 1) % 5 == 0 ? '' : 'margin-r-10'"
					>
						<!-- v-if="index !=1" -->
						<!-- <div class="showmodol" v-if="index==1">
								<div class="modtext" >
									已售罄
									<p></p>
								</div>
							</div> -->
						<div class="gcm-box-bottom-card-one-item-img-box flex-row-center-center"><img class="gcm-box-bottom-card-one-item-img" :src="item.fPicturePath" /></div>
						<div class="gcm-box-bottom-card-one-title font-size14 twoline-show-hide margin-t-20">{{ item.fGoodsFullName }}</div>
						<div class="flex-row width-fill" v-if="item.fActivityID != -1">
							<div class="gcm-box-bottom-card-one-price  font-size12 font-color-FF0000 margin-r-10">
								<span class="font-weight700 font-size16 padding-lr-5">{{ item.fActivityPrice }}</span>
								金币
							</div>
							<div class="gcm-box-bottom-card-one-price  font-size12 flex-row-align-center" style="text-decoration: line-through;">
								<span class="font-weight700 font-size12 padding-lr-5">{{ item.fGoodsPrice }}</span>
								金币
							</div>
						</div>
						<div class="flex-row width-fill" v-else>
							<div class="gcm-box-bottom-card-one-price  font-size12 font-color-FF0000">
								<span class="font-weight700 font-size16 padding-lr-5">{{ item.fGoodsPrice }}</span>
								金币
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pagination flex">
			<el-pagination background layout="prev, pager, next,jumper" prev-text="< 上一页" next-text="下一页 >" :page-size="requestParams.maxResultCount" :total="total"
			 @current-change="handleCurrentChange" :current-page="currentPage"></el-pagination>
			 <el-button size="mini" class="pageColor">共{{ Math.ceil(total / pagesize) }}页</el-button>
			 <el-button size="mini" @click="getGoodsList">确定</el-button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import parm from '@/components/loyout/publicList';
export default {
	data() {
		return {
			loading: false,
			flag : true,
			search: '', //搜索框文字
			leftItemShow: false,
			selectData: [],
			navigationButton: [
				{
					id: 1,
					icon: 'icon-zhangdan',
					title: '金币账单'
				},
				{
					id: 2,
					icon: 'icon-jinbi',
					title: '兑换金币'
				},
				{
					id: 3,
					icon: 'icon-order4',
					title: '金币订单'
				},
				{
					id: 4,
					icon: 'icon-gouwuche',
					title: '购物车'
				},
				{
					id: 5,
					icon: 'icon-wujiaoxing',
					title: '商品收藏'
				}
			], //跳转按钮
			navItems: parm.navItems,
			systemInfo: '',
			navBarBgc: {
				background: '#DAF8F0'
			},
			navHeight: 44,
			avatorUrl: '@/assets/imgs/GoldCoinMall/avator.png',
			cardOneList: parm.cardOneList,
			cashPrize: '@/assets/imgs/GoldCoinMall/gcm_bg.png',
			cardThreeList: parm.cardThreeList,
			goodsTabData: [
				{
					id: 1,
					name: '综合',
					checked: true,
					fSortMode: 1
				},
				{
					id: 2,
					name: '销量排序',
					checked: false,
					fSortMode: 1
				},
				{
					id: 3,
					name: '价格排序',
					checked: false,
					fSortMode: 1
				},
				// {
				// 	id: 4,
				// 	name: '看可兑换商品',
				// 	checked: false,
				// 	fIsExchange: 0
				// }
			],
			requestParams: {
				fGoodsClassID: '',
				fSortType: 1, //1 综合,2 销量,3 价格
				fSortMode: 1, //1 升序,2 降序
				maxResultCount: 30,
				filter: '',
				sorting: '',
				fIsExchange : 0,
				skipCount: 0
			},
			goodsList: [],
			total: null,
			currentPage: 1,
			pagesize: 30,
			flag1 : true
		};
	},
	computed: {
		...mapGetters(['getThemeName']),
		themeClass() {
			return `theme-${this.getThemeName}`;
		}
	},
	watch: {
		$route: {
			handler() {
				this.requestParams.fGoodsClassID = this.$route.query.id;
				this.requestParams.filter = this.$route.query.search;
				this.currentPage = 1;
				this.getGoodsList();

				this.$forceUpdate(); //强制刷新，解决页面不会重新渲染的问题
				//深度监听，同时也可监听到param参数变化
			},
			deep: true
		}
	},
	created() {
		console.log(this.$route);
		if (this.$route.query.id) {
			this.requestParams.fGoodsClassID = this.$route.query.id;
		}
		if (this.$route.query.search) {
			this.requestParams.filter = this.$route.query.search;
		}
		if(sessionStorage.getItem('LikePage') != null){
			this.currentPage = Number(sessionStorage.getItem('LikePage'))
			sessionStorage.removeItem('LikePage')
		}else{
			// console.log(this.currentPage,'看下当前这是第几页')
		}
		this.getGoodsList();
	},
	methods: {
		ExcClick(val){
			this.requestParams.fIsExchange == 0 ? this.requestParams.fIsExchange =1 : this.requestParams.fIsExchange =0;
			console.log(this.requestParams.fIsExchange)
			this.getGoodsList()
		},
		//换页、
		// pageChange(type) {
		// 	console.log('type', type);
		// 	if (type == '+') {
		// 		if (this.currentPage < Math.ceil(this.total / this.pagesize)) {
		// 			this.currentPage++;
		// 		}
		// 	} else {
		// 		if (this.currentPage > 1) {
		// 			this.currentPage--;
		// 		}
		// 	}
		// 	this.getGoodsList();
		// },
		//分页点击事件
		handleCurrentChange(val) {
			console.log(val)
			this.currentPage = val;
			this.getGoodsList(this.shopId);
		},
		async getGoodsList() {
			this.loading = true;
			console.error(this.currentPage)
			this.requestParams.skipCount = this.pagesize * (this.currentPage - 1);
			const res = await this.ApiRequestPost('api/mall/ebsale/goods-point-price/get-golden-goods-by-goodsclassid', this.requestParams);
			console.log(res);
			this.total = res.obj.totalCount;
			this.goodsList = res.obj.items;
			this.loading = false;
			console.log(this.goodsList, 'goodsList商品列表数据获取');
		},
		selectNavigationButton(e) {
			//金币账单
			if (e.id == 1) {
				console.log(e.title);
			}
			//兑换金币
			if (e.id == 2) {
				console.log(e.title);
			}
			//金币订单
			if (e.id == 3) {
				console.log(e.title);
			}
			//购物车
			if (e.id == 4) {
				console.log(e.title);
			}
			//商品收藏
			if (e.id == 5) {
				console.log(e.title);
			}
		},
		selectLeftItem(e) {
			for (let s of parm.navItems) {
				s.isSelect = false;
			}
			e.isSelect = true;
			this.leftItemShow = !this.leftItemShow;
		},
		jumpPage() {},
		// 跳转商品详情
		jumpCommodity() {},
		// 跳转商品更多列表
		jumpMore() {},
		// 兑换金币
		jumpExchange() {
			this.$router.push('/exchangeG');
		},
		getdetail(id) {
			// getdetail(id) {
			this.$router.push({
				path: '/goldMall/goodsDetails',
				query: {
					id: id
				}
			});
			//客户点击了具体的商品详情，就把它隶属于那一页的页码记住
			sessionStorage.setItem('LikePage',this.currentPage)
			// this.$router.push("/goodsDetails");
		},
		//商品tab点击事件
		goodsTabClick(val) {
			const m = val;
			// for (let item of this.goodsTabData) {
				//首先判断点的是可兑换商品并且是第一次点击
				if(m.name == '看可兑换商品' && this.flag){
					for(var i = 0; i < this.goodsTabData.length; i++){
						if(this.goodsTabData[i].checked || this.goodsTabData[i].name == '看可兑换商品'){
							this.goodsTabData[i].checked = true
						}else{
							this.goodsTabData.checked = false	
						}
					}
					//结束之前将flag置为false证明下次再点击可兑换商品就不是第一次点击了
					this.flag = false
					//如果点击的依旧是可兑换商品并且不是第一次点击
				}else if(m.name == '看可兑换商品' && !this.flag){
					for(var i = 0; i < this.goodsTabData.length; i++){
						//将可兑换商品置为false
						if(this.goodsTabData[i].name == '看可兑换商品'){
							this.goodsTabData[i].checked = false
						}
					}
					//对flag做初始化
					this.flag = true
					//上述都不满足证明点击的不是可兑换商品
				}else{
					//遍历数据
					for(var i = 0; i < this.goodsTabData.length; i++){
						//如果点击的是已经选中的，就改变他的状态
						if(this.goodsTabData[i].checked == m.checked){
							this.goodsTabData[i].fSortMode = m.fSortMode == 1 ? 2 : 1
							//如果点击的不是已经选中的，就再次遍历数据，将所有的数据初始化，并将现在点击的这个置为选中状态
						}else{
							this.goodsTabData.map(item=>{
								if(item.id == m.id){
									item.checked = true
									item.fSortMode == 1 ? 2 : 1
								}else if(item.name != '看可兑换商品'){
								    item.fSortMode = 1
									item.checked = false
								}
							})
							// this.flag1 = true
						}
					}
				}
				//以上代码负责选中和改变状态
				//最后再次遍历数据如果可兑换商品是选中状态就将fIsExchange置为1否则就是0
				// this.goodsTabData.map(item=>{
				// 	alert('111111111111111111111111111111111')
				// 	if(item.name == '看可兑换商品' && item.checked){
				// 		this.requestParams.fIsExchange = 1
				// 	}else{
				// 		this.requestParams.fIsExchange = 0
				// 	}
				// })
			this.requestParams.fSortMode = m.fSortMode;
			this.$forceUpdate();
			this.requestParams.fSortType = val.id;
			this.getGoodsList();
			console.log(this.requestParams.fSortType, this.requestParams.fSortMode);
		}
	}
};
</script>

<style lang="scss" scoped>
.tab {
	width: 70px;
	height: 35px;
	line-height: 35px;
}

.page-box {
	background-color: #f8f9fe;
}

.color-theme {
	@include themify($themes) {
		color: themed('themes_color');
	}
}

.textfon {
	font-weight: 700;

	@include themify($themes) {
		color: themed('themes_color');
	}
}

.bg-color-theme {
	@include themify($themes) {
		background-color: themed('themes_color');
	}
}

.not-found-box {
	height: 58px;
	margin: 2px auto 0;
	background-color: #ffffff;
	border-radius: 5px;
}

.not-found-innerbox {
	width: 100%;
	margin: 0 15px;
	padding: 0 15px;
	background-color: #fff2f2;
	height: 30px;
}

.goodsTab-box {
	padding-left: 10px;
	padding-right: 10px;
	height: 35px;
	line-height: 35px;
	border-left: 1px solid #f2f2f2;
	border-top: 1px solid #f2f2f2;
	border-bottom: 1px solid #f2f2f2;
}

.top-bg-box {
	background-color: #daf8f0;
}

.select-box {
	border-bottom: 1px dashed #f2f2f2;
}

.select-left {
	min-height: 46px;
	height: auto;
	width: 118px;
}

.select-right {
	width: 981px;
}

.right-box {
	height: 26px;
	line-height: 26px;
}

.navigation-box {
	margin: 0 auto;
	box-shadow: 0px 0px 10px rgba(204, 204, 204, 0.35);
}

.navigation-box-left {
	padding-top: 20px;
	width: 171px;
	background-color: #ffffff;
}

.navigation-box-right {
	width: 204px;
	height: 382px;
	padding: 22px 8px 0;
	background-color: #ffffff;
}

.gold-mall-head-box {
	margin-top: 30px;
	height: 204px;
}

.gold-mall-head-tatle-box {
	margin: auto;
	height: 86px;
	background-color: #25c6c6;
}

.icon-box-bg {
	background-color: #d9f7ef;
	width: 30px;
	height: 30px;
	background: inherit;
	background-color: rgba(217, 247, 239, 1);
}

.gold-mall-content-box {
	margin: auto;
}

.font-size36 {
	font-size: 36px;
}

.membership-level-two {
	margin-left: 105px;
}

.gcm-box-top {
	width: 709px;
	height: 404px;
	background-image: url(../../assets/imgs/GoldCoinMall/goldCoinMall_bg_1.png);
	background-size: 100% 100%;
}

.gcm-box-top-avator-box {
	width: 54px;
	height: 54px;
}

.gcm-box-top-avator {
	width: 54px;
	height: 54px;
}

.gcm-box-top-content-num {
	line-height: 70px;
}

.gcm-box-top-btn {
	width: 54px;
	height: 54px;
	border: 1px solid #1aa98b;
	background-color: #1aa98b;
	text-align: center;
	border-radius: 4px;
	color: #1aa98b;
}

.strip {
	width: 154px;
	background-color: #cccccc;
	top: 19px;
	left: 22px;
	height: 5px;
}

.strip-bg {
	background-color: #ffbb2d;
	width: 50%;
	top: 0px;
	left: 0px;
	height: 5px;
}

.gcm-box-bottom-top-bg {
	width: 702px;
	height: 68px;
}

.gcm-box-bottom-top-bg-crude-line {
	width: 44px;
	height: 5px;
	background: #e4e4e4;
	border-radius: 10px;
}

.integral-box {
	padding-bottom: 10px;
}

.gcm-box-bottom-card-one-nav {
	width: 100%;
	height: 48px;
}

.button-iconfont-color {
	color: #0e8d7a;
}

.gcm-box-bottom-card-one-time-box {
	margin-left: 12px;
}

.gcm-box-bottom-card-one-time-title {
	padding: 2px 20px 2px 20px;
	text-align: center;
}

.gcm-box-bottom-card-one-time {
	padding: 0 40px;
	text-align: center;
}

.gcm-box-bottom-card-one-list {
	margin-top: 5px;
	width: 100%;
}

.gcm-box-bottom-card-one-item {
	border: 1px solid #f2f2f2;
	width: 205px;
	height: 289px;
	position: relative;
}

.gcm-box-bottom-card-one-item-img-box {
	width: 186px;
	height: 186px;
}

.gcm-box-bottom-card-one-item-img {
	width: 160px;
	height: 160px;
}

.gcm-box-bottom-card-one-title {
	width: 194px;
	height: 40px;
}

.gcm-box-bottom-card-one-price {
	// width: 100%;
}

.gcm-box-bottom-card-two {
	margin-top: 10px;
	border-radius: 10px;
	background-color: #b8dddc;
}

.gcm-box-bottom-card-two-left {
	padding: 12px 0 12px 107px;
	color: #0f695f;
}

.gcm-box-bottom-card-two-right {
	width: 331px;
	height: 89px;
}

.navigation-box-right-button {
	width: 67px;
	height: 80px;
}

.gcm-box-bottom-card-three {
	border: 1px solid #f2f2f2;

	@include themify($themes) {
		border-top: 1px solid themed('themes_color');
	}
}

.gcm-box-bottom-card-three-nav {
	padding: 20px 0px 20px 0px;
}

.gcm-box-bottom-card-three-item-img-box {
	width: 211px;
	height: 211px;
}

.gcm-box-bottom-card-three-item-img {
	max-width: 211px;
	max-height: 211px;
}

.gcm-box-bottom-card-three-title {
	line-height: 30px;
}

.border-botton-dashed {
	border-bottom: 1px dashed #f2f2f2;
}

.border-right-dashed {
	border-right: 1px dashed #f2f2f2;
}

.left-item-list-box {
	top: 0px;
	left: 171px;
	background-color: #ffffff;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
	min-width: 689px;
	max-width: 689px;
}

.left-item-title {
	padding: 5px 10px;
	background-color: #333333;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
	margin-bottom: 10px;
}

.left-item-detailed {
	margin: 0px 20px 10px 0;
}

.left-item-detailed:hover {
	@include themify($themes) {
		color: themed('themes_color');
	}
}

.member-img-box {
	width: 21px;
	height: 21px;
	z-index: 1;
}

.member-img {
	width: 21px;
	height: 21px;
}

.showmodol {
	border-width: 0px;
	position: absolute;
	left: 0px;
	top: 0px;
	width: 206px;
	height: 289px;
	background: inherit;
	background-color: rgba(233, 233, 233, 0.9);
	border: none;
	border-radius: 0px;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.modtext {
	font-weight: initial;
	top: calc(50% - 50px);
	position: absolute;
	left: calc(50% - 50px);
	/* top: 20%; */
	margin: 0 auto;
	font-size: 22px;
	text-align: center;
	line-height: 100px;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background-color: #666666;
	color: rgb(221, 221, 221);
}

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
	  background-color:#409eff; //修改后的背景图颜色
	  color: #fff;
  }
  
::v-deep .el-pagination{
	flex:1;
	text-align:right;
}

.flex{
	display: flex;
	flex-wrap: nowrap;
	justify-self: flex-end;
	justify-content: flex-end;
	align-items: center;
	margin :20px 0;
}
  
.pageColor{
	padding: 6px;
	text-align: right;
	color: #999999;
	// margin-left: 60px;
	font-size: 13px;
	border:none;
	// flex:0.2;
}
::v-deep .btn-next{
	width:10%;
}
::v-deep .btn-prev{
  	width:10%;
}
</style>
